import { FormFeedback, Input as RInput } from "reactstrap"

const Input = props => {
    return (
        <>
            <RInput
                {...props}
                invalid={props?.error}
            />
            <FormFeedback>{props?.error}</FormFeedback>
        </>
    )
}

export default Input