/* eslint-disable */

import { Table as RTable } from 'reactstrap'
import ComponentSpinner from '@components/spinner/Loading-spinner'
import ReactPaginate from 'react-paginate'

const Table = (props) => {

    const {
        header = [],
        loading = false,
        count,
        children,
        total = 0,
        itemsPerPage = 10,
        currentPage = 1,
        pagination = true,
        indexing = true,
        table_props = {}
    } = props

    return (
        <>
            <RTable responsive {...table_props}>
                {
                    loading ? <ComponentSpinner /> :
                        <>
                            <thead>
                                <tr>
                                    {
                                        indexing &&
                                        <th>
                                            #
                                        </th>
                                    }
                                    {
                                        header.map(item => <th>{item}</th>)
                                    }
                                </tr>
                            </thead>
                            {
                                count > 0 && children

                            }
                        </>
                }
            </RTable>
            {
                pagination && !loading && count > 0 &&
                <ReactPaginate
                    nextLabel=""
                    breakLabel="..."
                    previousLabel=""
                    pageCount={Math.ceil(total / itemsPerPage)}
                    activeClassName="active"
                    breakClassName="page-item"
                    pageClassName={"page-item"}
                    breakLinkClassName="page-link"
                    nextLinkClassName={"page-link"}
                    pageLinkClassName={"page-link"}
                    nextClassName={"page-item next"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item prev"}
                    onPageChange={props?.onPageChange}
                    containerClassName={"pagination react-paginate justify-content-end m-2"}
                />
            }
            {
                count <= 0 &&
                <div className='my-3 align-items-center justify-content-center d-flex w-100 '>
                    <h4 className='m-0'>No Data Found</h4>
                </div>
            }
        </>
    )
}

export default Table