/* eslint-disable */
import Input from '@components/input';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  List,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { useHandlePrfMutation } from "../../redux/apis/prf";
import Table from '../../@core/components/table';

const View = () => {

  const navigate = useNavigate();

  const location = useLocation();
  const { data } = location.state || {};

  const [submission, setSubmission] = useState({ status: '', remarks: '' })
  const [modal, setModal] = useState(false);

  const [update, { isSuccess }] = useHandlePrfMutation()

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (isSuccess) {
      toggle()
      navigate('/prfs')
    }
  }, [isSuccess])

  const onApprove = () => {
    setSubmission({ status: "approved", remarks: "" })
    toggle()
  }

  const onReject = () => {
    setSubmission({ status: "rejected", remarks: "" })
    toggle()
  }

  const onChange = event => {
    setSubmission(e => ({
      ...e,
      remarks: event.target.value
    }))
  }

  const onSubmit = () => {
    update({ ...submission, _id: data._id })
  }

  const renderTable = () => {

    let total_no_of_males = Number(data?.expected_audience?.upto_6?.males) + Number(data?.expected_audience?._10_30?.males) + Number(data?.expected_audience?._30_50?.males) + Number(data?.expected_audience?._50_seniors?.males) + Number(data?.expected_audience?.special_people?.males)
    let total_no_of_females = Number(data?.expected_audience?.upto_6?.females) + Number(data?.expected_audience?._10_30?.females) + Number(data?.expected_audience?._30_50?.females) + Number(data?.expected_audience?._50_seniors?.females) + Number(data?.expected_audience?.special_people?.females)

    return (
      <Table
        header={["Age Group", "No. of Males", "No. of Females", "Total"]}
        count={6}
        pagination={false}
        indexing={false}
        table_props={{ size: "sm", bordered: true }}
      >
        <tbody>
          <tr>
            <th>Upto 6</th>
            <td>{data?.expected_audience?.upto_6?.males}</td>
            <td>{data?.expected_audience?.upto_6?.females}</td>
            <td>{Number(data?.expected_audience?.upto_6?.males) + Number(data?.expected_audience?.upto_6?.females)}</td>
          </tr>
          <tr>
            <th>10 to 30</th>
            <td>{data?.expected_audience?._10_30?.males}</td>
            <td>{data?.expected_audience?._10_30?.females}</td>
            <td>{Number(data?.expected_audience?._10_30?.males) + Number(data?.expected_audience?._10_30?.females)}</td>
          </tr>
          <tr>
            <th>30 to 50</th>
            <td>{data?.expected_audience?._30_50?.males}</td>
            <td>{data?.expected_audience?._30_50?.females}</td>
            <td>{Number(data?.expected_audience?._30_50?.males) + Number(data?.expected_audience?._30_50?.females)}</td>
          </tr>
          <tr>
            <th>50 + & Seniors</th>
            <td>{data?.expected_audience?._50_seniors?.males}</td>
            <td>{data?.expected_audience?._50_seniors?.females}</td>
            <td>{Number(data?.expected_audience?._50_seniors?.males) + Number(data?.expected_audience?._50_seniors?.females)}</td>
          </tr>
          <tr>
            <th>Differently able People</th>
            <td>{data?.expected_audience?.special_people?.males}</td>
            <td>{data?.expected_audience?.special_people?.females}</td>
            <td>{Number(data?.expected_audience?.special_people?.males) + Number(data?.expected_audience?.special_people?.females)}</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>{total_no_of_males}</td>
            <td>{total_no_of_females}</td>
            <th className="table-light">{total_no_of_females + total_no_of_males}</th>
          </tr>
        </tbody>
      </Table>
    )
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>PRF Details</CardTitle>
          {
            data?.status !== "approved" && data?.is_able_to_update &&
            <div>
              <Button color="success" className="me-2" onClick={onApprove}>Approve</Button>
              <Button color="danger" onClick={onReject}>Reject</Button>
            </div>
          }
        </CardHeader>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Event Details</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg="4" md="4" xs="12">
              <Row>
                <h5>Event Title</h5>
                <p>{data?.event_title}</p>
              </Row>
            </Col>
            <Col lg="4" md="4" xs="12">
              <Row>
                <h5>Event Theme</h5>
                <p>{data?.event_theme?.name}</p>
              </Row>
            </Col>
            <Col lg="4" md="4" xs="6">
            </Col>
            <Col lg="12" md="12" xs="12">
              <Row>
                <h5>Venue</h5>
                <p>{data?.event_venue}</p>
              </Row>
            </Col>
            <Col lg="4" md="4" xs="6">
              <Row>
                <h5>Start Date</h5>
                <p>{data?.event_from_date}</p>
              </Row>
            </Col>
            <Col lg="4" md="4" xs="6">
              <Row>
                <h5>End Date</h5>
                <p>{data?.event_to_date}</p>
              </Row>
            </Col>
            <Col lg="4" md="4" xs="6">
              <Row>
                <h5>Timings</h5>
                <p>{data?.event_from_time} - {data?.event_to_time}</p>
              </Row>
            </Col>
            <Col lg="12" md="12" xs="12">
              <Row>
                <h5>Event Objective</h5>
                <p>{data?.event_objectives}</p>
              </Row>
            </Col>
            <Col lg="12" md="12" xs="12">
              <Row>
                <h5>Event Description</h5>
                <p>{data?.event_description}</p>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Expected Audience</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12" md="8">
              {renderTable()}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col xs="12" md="6">
              <CardTitle>Expected Expense</CardTitle>
              <Table
                header={["Entity Name", "Entity Value"]}
                count={6}
                pagination={false}
                indexing={false}
                table_props={{ size: "sm", bordered: true }}
              >
                <tbody>
                  {
                    data.expected_expenses.map(item => (
                      <tr>
                        <th>{item.key}</th>
                        <td>{item.value}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
            <Col xs="12" md="6">
              <CardTitle>Expected Income</CardTitle>
              <Table
                header={["Entity Name", "Entity Value"]}
                count={6}
                pagination={false}
                indexing={false}
                table_props={{ size: "sm", bordered: true }}
              >
                <tbody>
                  {
                    data.expected_incomes.map(item => (
                      <tr>
                        <th>{item.key}</th>
                        <td>{item.value}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Other Information</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Row>
                <h5>Guest Speaker</h5>
                <p>{data?.guest_speaker || "N/A"}</p>
              </Row>
            </Col>
            <Col lg="12" md="12" xs="12">
              <h5>Collaborating Institutes</h5>
              {
                data?.collaborating_institutes?.length <= 0 ? <p>N/A</p> :
                  <List>
                    {
                      data?.collaborating_institutes?.map(item => (
                        <li>{item}</li>
                      ))
                    }
                  </List>
              }
            </Col>
            <Col lg="12" md="12" xs="12">
              <Row>
                <h5>Comments</h5>
                <p>{data?.comments}</p>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {submission?.status === "approved" ? "Approve" : "Reject"} PRF
        </ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            placeholder="Enter Comments Here ..."
            value={submission.remarks}
            onChange={onChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button outline>Cancel</Button>
          <Button color="success" onClick={onSubmit}>Submit</Button>
        </ModalFooter>
      </Modal>
    </div >
  );
};

export default View;
