import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/auth/`

export const authApiService = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                method: "POST",
                url: "login",
                body
            })
        })
    })
})

export const {
    useLoginMutation
} = authApiService