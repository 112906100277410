import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { objectToQueryString } from '@utils'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/jamatkhana/`

export const jamatkhanaApiService = createApi({
    reducerPath: 'jamatkhanaApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {

            const token = getState()?.general?.token

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints: (builder) => ({
        getJamatkhana: builder.query({
            query: (args) => {
                const query = objectToQueryString(args)

                return `get?${query}`
            },
            keepUnusedDataFor: 0
        }),
        addJamatkhana: builder.mutation({
            query: (body) => ({
                method: "POST",
                url: "create",
                body
            })
        })
    })
})

export const {
    useGetJamatkhanaQuery,
    useLazyGetJamatkhanaQuery,
    useAddJamatkhanaMutation
} = jamatkhanaApiService