import { createSlice } from '@reduxjs/toolkit'
import { generalApiService } from './apis/general'
import { authApiService } from './apis/auth'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        settings: null,
        token: null,
        user: null,
        lov: null
    },
    reducers: {
        login: (state, action) => {
            state.token = action.payload.token
            state.user = action.payload.user
        },
        logout: (state) => {
            state.token = null
            state.user = null
            generalApiService.util.resetApiState()
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(generalApiService.endpoints.getGeneralLov.matchFulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.settings = action.payload.data
            }
        })
        builder.addMatcher(generalApiService.endpoints.getRoleWiseLov.matchFulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.lov = action.payload.data
            }
        })
        builder.addMatcher(authApiService.endpoints.login.matchFulfilled, (state, action) => {
            if (action?.payload?.success) {
                state.token = action.payload.token
                state.user = action.payload.data

                sessionStorage.setItem('user', JSON.stringify(action.payload.data))
                sessionStorage.setItem('token', JSON.stringify(action.payload.token))
            }
        })
    }
})

export const {
    logout,
    login
} = generalSlice.actions

export default generalSlice.reducer
