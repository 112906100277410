/* eslint-disable */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { objectToQueryString } from "@utils";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/user/`;

export const userApiService = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {

      const token = getState()?.general?.token

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (args) => {
        const query = objectToQueryString(args);

        return `get?${query}`;
      },
      keepUnusedDataFor: 0,
    }),
    createUser: builder.mutation({
      query: (body) => {
        return {
          method: "POST",
          url: "create",
          body,
        };
      },
    }),
  }),
});

export const { useCreateUserMutation, useGetUsersQuery } = userApiService;
