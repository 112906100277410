import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { objectToQueryString } from '@utils'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/council/`

export const councilApiService = createApi({
    reducerPath: 'councilApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { }) => {
            // const token = getState().authSlice.token

            // headers.set('Authorization', `Bearer ${token}`)

            return headers
        }
    }),
    endpoints: (builder) => ({
        getCouncil: builder.query({
            query: (args) => {
                const query = objectToQueryString(args)

                return `get?${query}`
            },
            keepUnusedDataFor: 0
        }),
        addCouncil: builder.mutation({
            query: (body) => ({
                method: "POST",
                url: "create",
                body
            })
        })
    })
})

export const {
    useGetCouncilQuery,
    useLazyGetCouncilQuery,
    useAddCouncilMutation
} = councilApiService