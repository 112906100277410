// ** Reducers Imports
import { boardApiService } from './apis/board'
import { councilApiService } from './apis/council'
import { generalApiService } from './apis/general'
import { jamatkhanaApiService } from './apis/jamatkhana'
import { prfApiService } from './apis/prf'
import { userApiService } from './apis/user'
import layout from './layout'
import navbar from './navbar'
import general from './general'
import { authApiService } from './apis/auth'
// import storage from 'redux-persist/lib/storage'
// import { persistReducer } from 'redux-persist'

// const persistConfig = {
//     key: 'root',
//     storage: storage
// }

const rootReducer = {
    navbar,
    layout,
    general,
    [councilApiService.reducerPath]: councilApiService.reducer,
    [jamatkhanaApiService.reducerPath]: jamatkhanaApiService.reducer,
    [boardApiService.reducerPath]: boardApiService.reducer,
    [userApiService.reducerPath]: userApiService.reducer,
    [prfApiService.reducerPath]: prfApiService.reducer,
    [generalApiService.reducerPath]: generalApiService.reducer,
    [authApiService.reducerPath]: authApiService.reducer
}

// const rootReducer = persistReducer(persistConfig, rootReducers)

export default rootReducer
