// ** Redux Imports
import Swal from 'sweetalert2'
import { authApiService } from './apis/auth'
import { boardApiService } from './apis/board'
import { councilApiService } from './apis/council'
import { generalApiService } from './apis/general'
import { jamatkhanaApiService } from './apis/jamatkhana'
import { prfApiService } from './apis/prf'
import { userApiService } from './apis/user'
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'

const apiErrorHandler = (store) => (next) => (action) => {
  if (action.type.endsWith('/rejected')) {

    const { data, status } = action.payload
    const { message } = data

    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success"
      },
      buttonsStyling: false
    }).fire({
      title: "Error",
      text: message,
      icon: 'error',
      confirmButtonColor: "#3085d6"
    })

    if (status === 500) {
      store.dispatch(logout())
    }

  }

  return next(action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(councilApiService.middleware)
      .concat(jamatkhanaApiService.middleware)
      .concat(boardApiService.middleware)
      .concat(userApiService.middleware)
      .concat(prfApiService.middleware)
      .concat(generalApiService.middleware)
      .concat(authApiService.middleware)
      .concat(apiErrorHandler)
  }
})

export { store }
