// ** React Imports
import { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

// ** Context Imports

const PrivateRoute = ({ children, route }) => {

  const { user } = useSelector(state => state.general)

  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  // const user = JSON.parse(localStorage.getItem('userData'))

  if (route) {
    // let action = null
    // let resource = null
    const restrictedRoute = false

    // if (route.meta) {
    //   restrictedRoute = route.meta.restricted
    // }
    if (!user) {
      return <Navigate to='/login' />
    }
    if (user && restrictedRoute) {
      return <Navigate to='/' />
    }
    if (user && restrictedRoute && user.role === 'client') {
      return <Navigate to='/access-control' />
    }
    // if (user && !ability.can(action || 'read', resource)) {
    //   return <Navigate to='/misc/not-authorized' replace />
    // }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
