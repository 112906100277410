import { Mail, Home, User } from 'react-feather'

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={20} />,
    navLink: '/home'
  },
  {
    id: 'users',
    title: 'Users',
    icon: <User size={20} />,
    navLink: '/users',
    access: ['admin', 'president', 'chairperson']
  },
  {
    id: 'prfs',
    title: 'PRFs',
    icon: <Mail size={20} />,
    navLink: '/prfs'
  },
  {
    id: 'councils',
    title: 'Councils',
    icon: <Mail size={20} />,
    navLink: '/councils',
    access: ['admin']
  },
  {
    id: 'jamatkhana',
    title: 'Jamatkhana',
    icon: <Mail size={20} />,
    navLink: '/jamatkhana',
    access: ['admin']
  },
  {
    id: 'boards',
    title: 'Boards',
    icon: <Mail size={20} />,
    navLink: '/boards',
    access: ['admin']
  }
]
