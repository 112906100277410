// ** React Imports
/* eslint-disable */
import { Fragment, lazy } from "react";
import { Navigate } from "react-router-dom";
// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute";
import PrivateRoute from "@components/routes/PrivateRoute";

// ** Utils
import { isObjEmpty } from "@utils";
import View from "../../views/Prfs/view";

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Document title
const TemplateTitle = "%s - Karimabad Council Portal";

// ** Default Route
const DefaultRoute = "/home";

const Home = lazy(() => import("../../views/Home"));
const SecondPage = lazy(() => import("../../views/SecondPage"));
const Council = lazy(() => import("../../views/Council"));
const AddCouncil = lazy(() => import("../../views/Council/add"));
const Jamatkhana = lazy(() => import("../../views/Jamatkhana"));
const AddJamatkhana = lazy(() => import("../../views/Jamatkhana/add"));
const Boards = lazy(() => import("../../views/Board"));
const Prfs = lazy(() => import("../../views/Prfs"));
const AddPrf = lazy(() => import("../../views/Prfs/add"));
const Login = lazy(() => import("../../views/Login"));
const Register = lazy(() => import("../../views/Register"));
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"));
const Error = lazy(() => import("../../views/Error"));
const AddBoard = lazy(() => import("../../views/Board/AddBoard"));
const User = lazy(() => import("../../views/User"));
const AddUser = lazy(() => import("../../views/User/addUser"));
const ViewUser = lazy(() => import("../../views/User/view"));

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/home",
    element: <Home />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/second-page",
    element: <SecondPage />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/users",
    element: <User />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/users/add",
    element: <AddUser />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/users/view",
    element: <ViewUser />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/councils",
    element: <Council />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/councils/add",
    element: <AddCouncil />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/jamatkhana",
    element: <Jamatkhana />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/jamatkhana/add",
    element: <AddJamatkhana />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/boards",
    element: <Boards />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/prfs",
    element: <Prfs />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/prfs/view",
    element: <View />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/prfs/add",
    element: <AddPrf />,
    meta: {
      restricted: true,
    },
  },
  {
    path: "/boards",
    element: <Boards />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/boards/add",
    element: <AddBoard />,
    meta: {
      restricted: true,
      access: ['admin']
    },
  },
  {
    path: "/login",
    element: <Login />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank",
    },
  },
];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        var RouteTag = PublicRoute;

        // ** Check for public or private route

        if (route.meta.restricted) {
          RouteTag = PrivateRoute
        }

        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
              LayoutWrapper
              : Fragment;

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "horizontal", "blank"];

  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export { DefaultRoute, TemplateTitle, Routes, getRoutes };
