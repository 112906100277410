/* eslint-disable */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { objectToQueryString } from "@utils";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/board/`;

export const boardApiService = createApi({
  reducerPath: "boardApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { }) => {
      // const token = getState().authSlice.token

      // headers.set('Authorization', `Bearer ${token}`)

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBoards: builder.query({
      query: (args) => {
        const query = objectToQueryString(args);

        return `get?${query}`;
      },
      keepUnusedDataFor: 0,
    }),
    createBoard: builder.mutation({
      query: (body) => {
        return {
          method: "POST",
          url: "create",
          body,
        };
      },
    }),
  }),
});

export const { useGetBoardsQuery, useLazyGetBoardsQuery, useCreateBoardMutation } = boardApiService;
