import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { objectToQueryString } from '@utils'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/prf/`

export const prfApiService = createApi({
    reducerPath: 'prfApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {

            const token = getState()?.general?.token

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints: (builder) => ({
        getPrfs: builder.query({
            query: (args) => {
                const query = objectToQueryString(args)

                return `get?${query}`
            },
            keepUnusedDataFor: 0
        }),
        addPrf: builder.mutation({
            query: (body) => ({
                method: "POST",
                url: "create",
                body
            })
        }),
        handlePrf: builder.mutation({
            query: (body) => ({
                method: "PATCH",
                url: `status/${body._id}`,
                body
            })
        })
    })
})

export const {
    useGetPrfsQuery,
    useAddPrfMutation,
    useHandlePrfMutation
} = prfApiService